import './Css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Views/Home';
import PageNotFound from './Views/PageNotFound';

function App() {
  return (
    <div >
      <Router>
        <Routes>
          <Route path="/" element={<PageNotFound />} />
          <Route path="/:d" element={<Home />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
