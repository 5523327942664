import styles from '../Css/PageNotFound.module.css';


const PageNotFound = () => {

    return (
        <div className={`${styles.parent}`}>
            <section className={`${styles.banSec}`}>
                <div></div>
                <div>
                    <h1>Oops!!!</h1><br />
                </div>
                <div>
                    <h2>The page you are trying to reach does not exist.
                        Click the button below to return to the Home page
                    </h2>
                </div>
                <div>
                    <a href="https://www.sight-eduplus.com">Go Home</a>
                </div>
            </section>
        </div>
    );
};

export default PageNotFound;