import { useState, useEffect, useRef } from "react";
import Api from '../HelperFunctions/Api2';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from "react-router-dom";
import { setNote } from "../Redux/Reducers/noteReducer";
import Loading from "../Components/loading";
import HTMLReactParser from 'html-react-parser';

const Home = () =>{
    const dispatch = useDispatch();
    const note = useSelector(store=>store.note.value)
    const [showLoading, setShowLoading] = useState(true);
    const {d} = useParams();
    const iframeRef = useRef();

    useEffect(()=>{
        console.log(d, 'query')
        const getData = () =>{
            Api.getApi(`/learning/get-note/${d}`)
            .then(result=>{
                console.log(result, 'response')
                if(result.isSuccessful){
                    
                    dispatch(setNote(result.data));
                    setShowLoading(false);
                }
                else{
                    alert(result.message)
                    if(result.is_redirect)
                        window.location.href= result.data;
                    else window.location.href="https://sight-eduplus.com"
                }
            })
        }

        getData();
    }, [d])

    /*useEffect(()=>{
        if(note)
          if(!note.is_created){
            iframeRef.current.src = 
          } 
    },[note])*/

    return (
        <>
            <Loading shouldShow={showLoading} />
            {
                note.id && (            
                        note.is_created ? (
                            <div className="noteContentMain">
                                <div className="noteContent">
                                    {
                                        HTMLReactParser(note.note)
                                    }
                                </div>
                            </div>
                        ) : (
                                <iframe className="noteContentMain2" ref={iframeRef} src={`https://api.sight-eduplus.com/upload/notes/${note.note}#toolbar=0`}>
                                </iframe>                        
                        )
                    
                )
            }
        </>
    )
}

export default Home;